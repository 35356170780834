import * as React from "react"
import Layout from '../components/layout'
import { Link } from "gatsby"

const NotFoundPage = ({ location }) => {
  return (
    <Layout
      title="Wrong Turn"
      pathname={location.pathname}
    >
      <title>Not found</title>
      <h1>Page not found</h1>      
      <Link to="/">Go home</Link>.
    </Layout>
  )
}

export default NotFoundPage